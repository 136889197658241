import React, { useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { createNewsletterSignup } from 'src/services/newsletter.service';
import { useAuth0 } from '@auth0/auth0-react';

export const NewsletterSignupPage: React.FC = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const { getAccessTokenSilently } = useAuth0();

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleSubmit = async (e: { preventDefault: () => void }) => {
		const accessToken = await getAccessTokenSilently();
        e.preventDefault();
		setMessage(
			'Thank you for signing up! A verification email has been sent to your inbox.'
		);
		// setIsLoading(true);
		// setError('');

		const sessionData = { email: email };
		const { data, error } = await createNewsletterSignup(
			accessToken,
			sessionData
		);
		// if (error) {
		//     setError(error.message);
		//     setIsLoading(false);
		// }
	};

	return (
		<PageLayout>
			<div className="newsletter-signup">
				<div>
					<h2 className="stock-filings__title">
						Sign Up for Our Newsletter
						<p className="hero-banner__headline__3">
							Stay ahead with exclusive insider insights and market trends
							delivered straight to your inbox.
						</p>
					</h2>
				</div>
				<form className="newsletter-signup__form" onSubmit={handleSubmit}>
					<input
						type="email"
						value={email}
						onChange={handleInputChange}
						placeholder="Enter your email"
						className="newsletter-signup__input"
						required
					/>
					<button type="submit" className="newsletter-signup__button">
						Subscribe
					</button>
				</form>
				{message && <p className="newsletter-signup__message">{message}</p>}
			</div>
		</PageLayout>
	);
};

export default NewsletterSignupPage;
