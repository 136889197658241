import React, { useState } from "react";
import { NavBarBrand } from "./NavBarBrand";
import { NavBarButtons } from "./NavBarButtons";
import { NavBarTabs } from "./NavBarTabs";
import { useNavigate } from "react-router-dom";
import { NavSearchBar } from "src/components/Search/NavSearchBar";

export const NavBar: React.FC = () => {
  const navigate = useNavigate();

  const handleSearch = (userSuppliedTicker: any) => {
      if (userSuppliedTicker) {
        navigate(`/stockDetails/${userSuppliedTicker}/financialData`);
      }
    };

  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <NavSearchBar onSearch={handleSearch} />
        <NavBarTabs />
        <NavBarButtons />
      </nav>
    </div>
  );
};
