import React, { useEffect, useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { GenericTanStackTable } from '../components/Table/GenericTable';
import { ColumnDef } from '@tanstack/react-table';
import { StockData } from 'src/models/stock-types';
import { getStockData } from 'src/services/stock.service';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingDotText from 'src/components/Loading/LoadingDotsText';
import { useNavigate } from 'react-router-dom';

export const StockTopRankedPage: React.FC = () => {
    const [stockData, setStockData] = useState<StockData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const handleRowClick = (ticker: string) => {
        navigate(`/stockDetails/${ticker}/financialData`);
    };

    useEffect(() => {
        const fetchStockData = async () => {
            const accessToken = await getAccessTokenSilently();
            setIsLoading(true);
            setError('');
    
            try {
                const response = await getStockData(accessToken, 60);
                console.log(response);
                const dataWithRanking = response.data?.map((item: StockData, index: number) => ({
                    ranking: index + 1,
                    ...item,
                }));
                setStockData(dataWithRanking || []);
            } catch (error) {
                setError('Error fetching stock data:' + error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchStockData();
    }, []);
    
    const columns: ColumnDef<StockData, any>[] = [
        {
            accessorKey: 'ranking',
            header: 'Ranking',
        },
        { accessorKey: 'ticker', header: 'Ticker' },
        { accessorKey: 'companyName', header: 'Company Name' },
        {
            accessorKey: 'insiderFilingScore',
            header: 'Filing Score',
            cell: (info) => `${info.getValue().toFixed(0)}%`,
        },
        {
            accessorKey: 'trendAdjustedScore',
            header: 'JDK Score',
            cell: (info) => `${info.getValue().toFixed(0)}%`,
        },
        {
            accessorKey: 'scoreScaled',
            header: 'Buy Timing Indicator',
            cell: (info) => `${info.getValue().toFixed(0)}%`,
        },
        {
            accessorKey: 'financialStrengthPercentage',
            header: 'Financial Stability',
            cell: (info) => `${info.getValue().toFixed(0)}%`,
        },
        {
            accessorKey: 'rating',
            header: 'Rating',
        },
        {
            accessorKey: 'ratingScore',
            header: 'Rating Score',
        },
        {
            accessorKey: 'recommendation',
            header: 'Recommendation',
        },
        {
            accessorKey: 'tradeDate',
            header: 'Trade Date',
            cell: (info) => new Date(info.getValue() as string).toLocaleDateString(),
        },
        {
            accessorKey: 'numberOfInsiderTraders',
            header: 'Insider Traders',
        },
        {
            accessorKey: 'purchasePrice',
            header: 'Purchase Price',
            cell: (info) => `$${(info.getValue() as number).toFixed(0)}`,
        },
        { accessorKey: 'quantity', header: 'Quantity' },
        {
            accessorKey: 'previouslyOwned',
            header: 'Previously Owned',
        },
        {
            accessorKey: 'percentageOwned',
            header: 'Percentage Owned',
            cell: (info) => `${info.getValue()}%`,
        },
        {
            accessorKey: 'purchaseTotal',
            header: 'Purchase Total',
            cell: (info) => `$${(info.getValue() as number).toLocaleString()}`,
        },
    ];
    
    return (
        <PageLayout>
            <div className="top_ranked_content-layout">
                {error && <p className="error-message">{error}</p>}
                <div>
                    <h2 className="stock-filings__title">Top Insider Filings <p className='hero-banner__headline__3'>Discover the best-performing stocks ranked by insider trading activity and algorithmic analysis of company finances</p></h2>
                    {/* <div className="select-container">
                        <div className="fetch-button-container">
                            <button className="button" onClick={fetchData}>
                                Get Filings
                            </button>
                        </div>
                    </div> */}
                </div>
                {isLoading ? (
                    <div>
                        <h3 className="yearly-scores-title">
                            <LoadingDotText text={`Loading data`} />
                        </h3>
                    </div>
                ) : (
                    <GenericTanStackTable data={stockData} columns={columns} onRowClick={(row) => handleRowClick(row.ticker)} />
                )}
            </div>
        </PageLayout>
    );
}