import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { MobileNavBarTab } from './MobileNavBarTab';

interface MobileNavBarTabsProps {
	handleClick: () => void;
}

export const MobileNavBarTabs: React.FC<MobileNavBarTabsProps> = ({
	handleClick,
}) => {
	const { isAuthenticated } = useAuth0();

	return (
		<div className="mobile-nav-bar__tabs">
			{isAuthenticated ? (
				<>
					<MobileNavBarTab
						path="/" label="Home" 
						handleClick={handleClick}
					/>
					<MobileNavBarTab
						path="/stockFilings" label="Recent Filings" 
						handleClick={handleClick}
					/>
					<MobileNavBarTab
						path="/stockDetails/topRankedStocks" label="Top Filings"
						handleClick={handleClick}
					/>
					<MobileNavBarTab
						path="/newsletter-signup" label="Newsletter" 
						handleClick={handleClick}
					/>
				</>
			)
			:
			(
				<>
					<MobileNavBarTab
						path="/" label="Home" 
						handleClick={handleClick}
					/>
					<MobileNavBarTab
						path="/newsletter-signup" label="Newsletter" 
						handleClick={handleClick}
					/>
				</>
			)
		}
		</div>
	);
};
