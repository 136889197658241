import React, { useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { FaSearch } from 'react-icons/fa'; // Import the search icon from react-icons
import { useNavigate } from 'react-router-dom';

export const HomePage: React.FC = () => {
    const [userSuppliedTicker, setUserSuppliedTicker] = useState('');
    const navigate = useNavigate();
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserSuppliedTicker(e.target.value.toUpperCase());
    };

    const handleSearch = () => {
        if (userSuppliedTicker) {
          navigate(`/stockDetails/${userSuppliedTicker}/financialData`);
        }
      };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <PageLayout>
            <div className="home-page">
                <div className="hero-banner">
                    <h1 className="hero-banner__headline">Stock Tracker<br/><span className='hero-banner__headline__2'>Empowering Smarter Investments</span></h1>
                    {/* <p className="hero-banner__description">Discover insider trading insights and advanced stock analysis tools to stay ahead in the market.</p> */}
                </div>
                <div className="search-bar-container">
                    <div className="home__search">
                        <input
                            type="text"
                            value={userSuppliedTicker}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Search for a stock symbol (e.g., AAPL, TSLA)"
                            onFocus={(e) => (e.target.placeholder = '')}
                            onBlur={(e) => (e.target.placeholder = 'Search for a stock')}
                        />
                        <button onClick={handleSearch} className="search-button">
                            <FaSearch />
                        </button>
                    </div>
                </div>
                <div className="features-section">
                    <h2 className="features-section__title"></h2>
                    <div className="features-grid">
                        <div className="feature-card">
                        <img src="https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Card Image" className="feature-card__image" />
                            <div className='featyre-card__content'>
                                <h3 className="feature-card__title">Real-Time Insider Trading Insights</h3>
                                <p className="feature-card__description">Access up-to-date insider trading data to spot potential trends and valuation shifts.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                        <img src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Card Image" className="feature-card__image" />
                            <div className='featyre-card__content'>
                                <h3 className="feature-card__title">Advanced Stock Analysis</h3>
                                <p className="feature-card__description">Leverage algorithm-driven insights on financial health, investment indicators, and stock purchase recommendations.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                        <img src="https://images.unsplash.com/photo-1596526131083-e8c633c948d2?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Card Image" className="feature-card__image" />
                            <div className='featyre-card__content'>
                                <h3 className="feature-card__title">Custom Alerts and Notifications</h3>
                                <p className="feature-card__description">Receive personalized updates on key investment opportunities straight to your inbox.</p>
                            </div>
                        </div>
                        <div className="feature-card">
                        <img src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Card Image" className="feature-card__image" />
                            <div className='featyre-card__content'>
                                <h3 className="feature-card__title">Custom API Access</h3>
                                <p className="feature-card__description">Access our API's directly for automated data retrieval. Only for customers with premium plan.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
};