import { Link } from 'react-router-dom';
import { FinancialSummary, Stock } from 'src/models/stock-types';

interface StockFilingCardProps extends Stock {
    href: string;
}

export const StockFilingCard: React.FC<StockFilingCardProps> = ({
    id,
    filingDate,
    tradeDate,
    ticker,
    companyName,
    industry,
    numberOfInsiderTraders,
    tradeType,
    purchasePrice,
    quantity,
    previouslyOwned,
    percentageOwned,
    purchaseTotal,
    ownershipIncrease,
    insiderFilingScore,
    latestFinancialScore,
    trendAdjustedScore,
    financialStrengthPercentage,
    rating,
    ratingScore,
    recommendation,
    isBuySignal,
    rawScore,
    scoreScaled,
    explanation,
    href,
}) => {
    const decodeHtmlEntities = (str: string) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    };
    const decodedTitle = decodeHtmlEntities(companyName);

    const getClassForValue = (value: number, max: number = 100) => {
        const percentage = (value / max) * 100;
        if (percentage >= 0 && percentage < 30 || percentage < 0) {
            return 'red-metric';
        } else if (percentage >= 30 && percentage < 60) {
            return 'orange-metric';
        } else if (percentage >= 60 && percentage <= 100) {
            return 'green-metric';
        }
        return '';
    };

    return (
        <div className="stockFilingCard-container">
            <Link to={href} className="stockFilingCard-link">
                <div>
                    <div className="p-6">
                        <h2 className="stockFilingCard-title">{decodedTitle}</h2>
                        <p className="stockFilingCard-header">{tradeDate}</p>
                        <p className="stockFilingCard-header">{ticker}</p>
                        <p className="stockFilingCard-header">{industry}</p>
                        <p className="stockFilingCard-paragraph">
                            Number of Insider Traders: <span style={{ color: 'white', fontSize: "1.8rem", paddingLeft: "0.5rem"}}> {numberOfInsiderTraders}</span>
                        </p>
                        <p className="stockFilingCard-paragraph">
                            Filing Score:
                            <span className={getClassForValue(insiderFilingScore)}>
                                {Math.round(insiderFilingScore)} %
                            </span>
                        </p>
                        <p className="stockFilingCard-paragraph">
                            JDK Score:
                            <span className={getClassForValue(trendAdjustedScore)}>
                                {Math.round(trendAdjustedScore)} %
                            </span>
                        </p>
                        <p className="stockFilingCard-paragraph">
                            FMP Score:
                            <span className={getClassForValue(ratingScore, 5)}>
                                {Math.round((ratingScore / 5) * 100)}%
                            </span>
                        </p>
                        <p className="stockFilingCard-paragraph">
                            Financial Stability:
                            <span className={getClassForValue(financialStrengthPercentage)}>
                                {financialStrengthPercentage}%
                            </span>
                        </p>
                        <p className="stockFilingCard-paragraph">
                            Buy Timing Indicator:
                            <span className={getClassForValue(scoreScaled)}>
                                {Math.round(scoreScaled)} %
                            </span>
                        </p>
                        <p className="stockFilingCard-paragraph">
                            Purchase Recommendation:
                            <span className={getClassForValue(ratingScore, 5)}>
                                {recommendation}
                            </span>
                        </p>
                        {/* <p className="stockFilingCard-paragraph">
                            Explanation:
                            <span style={{ color: 'white' }}> {explanation}</span>
                        </p> */}
						{/* <p className="stockFilingCard-paragraph">
							Purchase Price: ${purchasePrice.toFixed(2)}
						</p>
						<p className="stockFilingCard-paragraph">
							Ownership Increase: {ownershipIncrease}%
						</p>
						<p className="stockFilingCard-paragraph">Quantity: {quantity}</p> */}
						Read more &rarr;
					</div>
				</div>
			</Link>
		</div>
	);
};
