import React from 'react';
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    ColumnDef,
} from '@tanstack/react-table';

type TanStackTableProps<T> = {
    data: T[];
    columns: ColumnDef<T, any>[];
    onRowClick?: (row: T) => void; 
};

const getClassForValue = (value: number) => {
    if (value < 30) {
        return 'red-metric';
    } else if (value >= 30 && value < 60) {
        return 'orange-metric';
    } else if (value >= 60) {
        return 'green-metric';
    }
    return '';
};

const getClassForRating = (value: number) => {
    if (value < 2) {
        return 'red-metric';
    } else if (value >= 2 && value < 4) {
        return 'orange-metric';
    } else if (value >= 4) {
        return 'green-metric';
    }
    return '';
};

export const GenericTanStackTable = <T,>({ data, columns, onRowClick }: TanStackTableProps<T>) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="tanstack-table-container">
            <table className="tanstack-table">
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th key={header.id} className="sticky">
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} onClick={() => onRowClick?.(row.original)}>
                            {row.getVisibleCells().map((cell, index) => {
                                const cellValue = cell.getValue();
                                const cellClass = typeof cellValue === 'number' && [3, 4, 5, 6].includes(index)
                                    ? getClassForValue(cellValue)
                                    : '';

                                if ([7, 8, 9].includes(index)) {
                                    const ratingValue = row.getVisibleCells()[7].getValue() as number;
                                    const ratingClass = getClassForRating(ratingValue);
                                    return (
                                        <td key={cell.id} className={ratingClass}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    );
                                }

                                return (
                                    <td key={cell.id} className={cellClass}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};