import React from 'react';
import { Score, YearlyScore } from 'src/models/stock-types';

interface StockDetailProps {
    year: number;
    quarter: string;
    yearScore: YearlyScore;
    previousYearScore: YearlyScore | null;
}

export const StockDetail: React.FC<StockDetailProps> = ({
    year,
    quarter,
    yearScore,
    previousYearScore,
}) => {
    const capitalize = (str: string) => {
        return str
            .replace(/([A-Z])/g, ' $1') // Add space before each capital letter
            .replace(/^./, (s) => s.toUpperCase()) // Capitalize the first letter
            .trim(); // Remove leading and trailing spaces
    };

    const formatPercentage = (value: number) => {
        return `${(value * 100).toFixed(2)}%`;
    };

    const formatMonetary = (value: number) => {
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    };

    const formatDifference = (
        current: number,
        previous?: number,
        isPercentage?: boolean,
        field?: string
    ) => {
        if (previous === undefined) {
            return <span className="neutral-difference"></span>;
        }
        const difference = current - previous;
        let formattedDifference;
        let differenceClass = 'neutral-difference';

        switch (field) {
            case 'grossProfitMargin':
            case 'netProfitMargin':
            case 'roic':
            case 'dividendYield':
                formattedDifference = formatPercentage(difference);
                break;
            case 'revenuePerShare':
            case 'netIncomePerShare':
            case 'freeCashFlowPerShare':
            case 'peRatio':
            case 'debtToEquity':
            case 'currentRatio':
                formattedDifference = formatMonetary(difference);
                break;
            default:
                formattedDifference = isPercentage
                    ? formatPercentage(difference)
                    : formatMonetary(difference);
        }

        if (difference > 0) {
            differenceClass = 'positive-difference';
        } else if (difference < 0) {
            differenceClass = 'negative-difference';
        }

        return (
            <span className={differenceClass}>
                {difference > 0 ? `+${formattedDifference} ↑` : `${formattedDifference} ↓`}
            </span>
        );
    };

    return (
        <div className="detail-container">
            <ul>
                <h1 className="detail__year">{quarter}-{year}</h1>
                {Object.entries(yearScore.scores).map(([key, value]) => {
                    const safeKey = key as keyof Score;
                    const previousValue = previousYearScore
                        ? previousYearScore.scores[safeKey]
                        : undefined;

                    const isPercentage = ['grossProfitMargin', 'netProfitMargin', 'roic', 'dividendYield'].includes(key);
                    const isMonetary = ['revenuePerShare', 'netIncomePerShare', 'freeCashFlowPerShare', 'peRatio', 'debtToEquity'].includes(key);
                    const formattedValue = isPercentage
                        ? formatPercentage(value)
                        : isMonetary
                        ? formatMonetary(value)
                        : value.toFixed(2);

                    return (
                        <li className="detail__description" key={key}>
                            <span className='detail__description__label'>{`${capitalize(key)}:`}</span>
                            <span className='detail__description__value'>{`${formattedValue} `}</span>
                            <span className='detail__description__change'>{formatDifference(value, previousValue, isPercentage, key)}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};