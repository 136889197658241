import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa'; // Import the search icon from react-icons

interface SearchBarProps {
  onSearch: (ticker: string) => void;
}

export const NavSearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [userSuppliedTicker, setUserSuppliedTicker] = useState('');
  const { isAuthenticated } = useAuth0();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserSuppliedTicker(e.target.value.toUpperCase());
  };

  const handleSearch = () => {
    if (userSuppliedTicker) {
      onSearch(userSuppliedTicker);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="nav-bar__search">
      {
        isAuthenticated && (
        <>
          <input
          type="text"
          value={userSuppliedTicker}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress} // Add the onKeyPress event handler
          placeholder="Analyze a stock symbol"
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Analyze a stock symbol')}
          />
          <button onClick={handleSearch} className="nav-bar__search-button">
              <FaSearch />
          </button>
        </>
        )
      }
    </div>
  );
};