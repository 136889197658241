import React from 'react';
import { NavBar } from './Navigation/Desktop/NavBar';
import { MobileNavBar } from './Navigation/Mobile/MobileNavBar';
import { PageFooter } from './PageFooter';

interface PageLayoutProps {
	children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
	return (
		<div className="page-layout">
			<NavBar />
			<MobileNavBar />
			<div className="page-layout__content">{children}</div>
			{/* <PageFooter /> */}
		</div>
	);
};
