import React, { useState } from 'react';
import { PageLayout } from '../components/PageLayout';
import { createNewsletterSignup } from 'src/services/newsletter.service';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'src/components/Loading/LoadingSpinner';

export const NewsletterSignupPage: React.FC = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const navigate = useNavigate();

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
		setTimeout(() => {
			window.history.replaceState(null, "");
			navigate("/", { replace: true });
		}, 5000);
		setMessage(
			'Thank you for signing up! A verification email has been sent to your inbox.'
		);
		const sessionData = { email: email };
		const { data, error } = await createNewsletterSignup(
			sessionData
		);
	};

	return (
		<PageLayout>
			<div className="newsletter-signup">
				<div>
					<h2 className="stock-filings__title">
						Sign Up for Our Newsletter
						<p className="hero-banner__headline__3">
							Stay ahead with exclusive insider insights and market trends
							delivered straight to your inbox.
						</p>
					</h2>
				</div>
				<form className="newsletter-signup__form" onSubmit={handleSubmit}>
					<input
						type="email"
						value={email}
						onChange={handleInputChange}
						placeholder="Enter your email"
						className="newsletter-signup__input"
						required
					/>
					<button type="submit" className="newsletter-signup__button">
						Subscribe
					</button>
				</form>
				{message && 
				(
				<div>
					<p className="newsletter-signup__message">
						{message}
					</p>
					<p className="hero-banner__headline__3">
						Redirecting to home page in 5 seconds
						<LoadingSpinner />
					</p>
				</div>
				)}

			</div>
		</PageLayout>
	);
};

export default NewsletterSignupPage;
