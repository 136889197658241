import React from 'react';

interface StockPriceChangeProps {
    priceChange: {
        symbol: string;
        oneDay: number;
        fiveDays: number;
        oneMonth: number;
        threeMonths: number;
        sixMonths: number;
        yearToDate: number;
        oneYear: number;
        threeYears: number;
        fiveYears: number;
        // tenYears: number;
        // max: number;
    };
}

const StockPriceChangeChart: React.FC<StockPriceChangeProps> = ({ priceChange }) => {
    const renderChange = (label: string, value: number) => {
        const changeClass = value >= 0 ? 'positive' : 'negative';
        const arrow = value >= 0 ? '↑' : '↓';
        return (
            <li className={changeClass}>
                <span>{label}</span>
                <span className="value">
                    {value.toFixed(2)}% {arrow}
                </span>
            </li>
        );
    };

    return (
        <div className="stock-price-change-chart">
            <ul>
                {/* {renderChange('Max', priceChange.max)}
                {renderChange('10 Years', priceChange.tenYears)} */}
                {renderChange('5 Years', priceChange.fiveYears)}
                {renderChange('3 Years', priceChange.threeYears)}
                {renderChange('1 Year', priceChange.oneYear)}
                {renderChange('Year to Date', priceChange.yearToDate)}
                {renderChange('6 Months', priceChange.sixMonths)}
                {renderChange('3 Months', priceChange.threeMonths)}
                {renderChange('1 Month', priceChange.oneMonth)}
                {renderChange('5 Days', priceChange.fiveDays)}
                {renderChange('1 Day', priceChange.oneDay)}
            </ul>
        </div>
    );
};

export default StockPriceChangeChart;