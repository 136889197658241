import React from 'react';

const dividerStyle: React.CSSProperties = {
    border: 'none',
    borderTop: '1px solid #ccc',
    margin: '30px 0',
};

const Divider: React.FC = () => {
    return <hr style={dividerStyle} />;
};

export default Divider;