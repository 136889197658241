import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { NavBarTab } from './NavBarTab';

export const NavBarTabs: React.FC = () => {
	const { isAuthenticated } = useAuth0();

	return (
		<div className="nav-bar__tabs">
			{/* <NavBarTab path="/public" label="Public" /> */}
			{isAuthenticated ? (
				<>
					<NavBarTab path="/" label="Home" />
					<NavBarTab path="/stockFilings" label="Recent Filings" />
					<NavBarTab path="/stockDetails/topRankedStocks" label="Top Filings" />
					<NavBarTab path="/newsletter-signup" label="Newsletter" />
					{/* <NavBarTab path="/profile" label="Profile" /> */}
					{/* <NavBarTab path="/stockDetails" label="Details" /> */}
				</>
			)
			:
			(
				<NavBarTab path="/newsletter-signup" label="Newsletter" />
			)
		}
		</div>
	);
};
