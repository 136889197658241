import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { getStockDetails, getStockPriceChange, getStockSummary } from '../../../services/stock.service';
import { FinancialSummary, StockPriceChange, YearlyScore } from 'src/models/stock-types';
import { LoadingDotText } from '../../Loading/LoadingDotsText';
import { StockFinancialSummary } from './StockFinancialSummary';
import StockDetailChart from './StockDetailChart';
import { StockDetail } from './StockDetail';
import StockPriceChangeChart from './StockPriceChangeChart';
import Divider from 'src/components/Divider/Divider';

interface StockDetailProps {
  ticker: string;
}

export const StockDetails: React.FC<StockDetailProps> = ({ ticker }) => {
  const [yearlyScores, setYearlyScores] = useState<YearlyScore[]>([]);
  const [financialSummary, setFinancialSummary] = useState<FinancialSummary>();
  const [stockPriceChange, setStockPriceChange] = useState<StockPriceChange>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [yearRange, setYearRange] = useState(5);
  const { getAccessTokenSilently } = useAuth0();
  const [isApiUnavailable, setIsApiUnavailable] = useState(false);
  const [showChart, setShowChart] = useState(false); // State to toggle between chart and list view

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      setIsLoading(true);
      setYearlyScores([]);
      setError('');

      const yearlyScoreResponse = await getStockDetails(accessToken, ticker, yearRange);
      const financialSummaryResponse = await getStockSummary(accessToken, ticker);
      const stockPriceChangeResponse = await getStockPriceChange(accessToken, ticker); 
      if (yearlyScoreResponse.data === undefined) {
        setError('No data available for the given ticker and year range.');
        setIsLoading(false);
        return;
      }
      if (financialSummaryResponse.data === null) {
        setError('No data available for the given ticker and year range.');
        setIsLoading(false);
      }
      if (stockPriceChangeResponse.data === null) {
        setError('No stock price change data available for the given ticker and year range.');
        setIsLoading(false);
      }

      if (stockPriceChangeResponse.data) {
        setStockPriceChange(stockPriceChangeResponse.data);
      }

      if (yearlyScoreResponse.data) {
        setYearlyScores(yearlyScoreResponse.data);
      }

      if (financialSummaryResponse.data) {
        setFinancialSummary(financialSummaryResponse.data);
      }

      if (yearlyScoreResponse.error || financialSummaryResponse.error) {
        setError('Error retrieving financial data. Please try again later.');
      }

      setIsLoading(false);
    };

    fetchData();
  }, [ticker, yearRange]);

  return (
    <div className="stock-details">
      {isApiUnavailable && (
        <p className="api-status-message">
          The API is temporarily unavailable. Please try again later.
        </p>
      )}
      
      {isLoading ? (
        <div>
          <h3 className="stock-details__title">
            <LoadingDotText text={`Loading data for ${ticker}`} />
          </h3>
        </div>
      ) : (
        <div>
          {error && <p className="error-message">{error}</p>}
          {financialSummary && (
            <StockFinancialSummary financialSummary={financialSummary} />
          )}
          <Divider />
          <label className="select-label" htmlFor="yearRange">
            Price Change
          </label>
          {stockPriceChange && (
            <StockPriceChangeChart priceChange={stockPriceChange} />
          )}
          <Divider />
          <div>
            <label className="select-label">
              Indicators
            </label>
          </div>
          <button className="toggle-button" onClick={() => setShowChart(!showChart)}>
            {showChart ? 'List View' : 'Chart View'}
          </button>
          <div >
            {showChart ? (
              <StockDetailChart yearlyScores={yearlyScores} />
            ) : (
              <>
                <div className="select-container">
                  <label className="select-label-quarters" htmlFor="yearRange">
                    Number of quarters
                  </label>
                  <select
                    className="custom-select"
                    value={yearRange}
                    onChange={(e) => setYearRange(Number(e.target.value))}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="stock-details__grid">
                {yearlyScores.slice().reverse().map((score, index) => (
                  <StockDetail
                    key={`${score.year}-${score.quarter}`}
                    year={score.year}
                    quarter={score.quarter}
                    yearScore={score}
                    previousYearScore={index > 0 ? yearlyScores.slice().reverse()[index - 1] : null}
                  />
                ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};