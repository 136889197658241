import { AxiosRequestConfig } from "axios";
import {
	AiAnalysisApiResponse,
	ApiResponse,
	StockDetailsApiResponse,
	StockFilingsApiResponse,
	StockDailyCloseApiResponse,
	FinancialSummaryApiResponse,
	StockDataApiResponse,
	StockPriceChangeApiResponse,
} from "../models/api-response";
import { fetchAiAnalysis, fetchStockData, fetchStockDetails, fetchStockFilings, fetchStockPriceChange, fetchStockSummary } from "./external-api.service";
import { AIAnalysis, FinancialSummary, Stock, StockDailyClosePrice, StockData, StockPriceChange, YearlyScore } from "src/models/stock-types";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const sendAPIWakeupRequest = async (): Promise<any> => {
	const config: AxiosRequestConfig = {
		url: `${apiServerUrl}/`,
		method: "GET",
		headers: {
			"content-type": "application/json",
		},
	};

	const { data, error } = (await fetchStockFilings({ config }));

	return {
		data,
		error,
	};
};

export const getTopFilings = async (
	accessToken: string
): Promise<ApiResponse<Stock[]>> => {
	const config: AxiosRequestConfig = {
		url: `${apiServerUrl}/api/topFilings/4`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = (await fetchStockFilings({
		config,
	})) as StockFilingsApiResponse;
	console.log("inner", data)
	return {
		data,
		error,
	};
};

export const getStockData = async (
	accessToken: string,
	amount: number
): Promise<ApiResponse<StockData[]>> => {
	const config: AxiosRequestConfig = {
		url: `${apiServerUrl}/api/stocks/getTopRanked/purchaseEvaluated/${amount}`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = (await fetchStockData({
		config,
	})) as StockDataApiResponse;
	return {
		data,
		error,
	};
};

export const getStockDetails = async (
	accessToken: string,
	ticker: string,
	yearRange: number
): Promise<ApiResponse<YearlyScore[]>> => {
	const config: AxiosRequestConfig = {
		url: `${apiServerUrl}/api/stocks/getHistoricalData/${ticker}/${yearRange}`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = (await fetchStockDetails({
		config,
	})) as StockDetailsApiResponse;

	return {
		data,
		error,
	};
};

export const getStockSummary = async (
    accessToken: string,
    ticker: string
): Promise<ApiResponse<FinancialSummary>> => {
    const config: AxiosRequestConfig = {
        url: `${apiServerUrl}/api/analyzeStock/${ticker}`,
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = (await fetchStockSummary({
        config,
    })) as FinancialSummaryApiResponse;
	
    return {
        data,
        error,
    };
};

export const getStockPriceChange = async (
	accessToken: string,
	ticker: string,
): Promise<ApiResponse<StockPriceChange>> => {
	const config: AxiosRequestConfig = {
		url: `${apiServerUrl}/api/marketData/priceChange/${ticker}`,
		method: "GET",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
	};

	const { data, error } = (await fetchStockPriceChange({
		config,
	})) as StockPriceChangeApiResponse;

	return {
		data,
		error,
	};
};

export const getAiAnalysis = async (
	accessToken: string,
	ticker: string,
	yearlyScores: number,
): Promise<ApiResponse<AIAnalysis>> => {
	const config: AxiosRequestConfig = {
		url: `${apiServerUrl}/api/semantic-kernel/stockEvaluation`,
		method: "POST",
		headers: {
			"content-type": "application/json",
			Authorization: `Bearer ${accessToken}`,
		},
		data: {
			ticker,
		},
	};

	const { data, error } = (await fetchAiAnalysis({
		config,
	})) as AiAnalysisApiResponse;

	return {
		data,
		error,
	};
};


// export const getStockDailyClosePrice = async (
// 	accessToken: string,
// 	ticker: string,
// ): Promise<ApiResponse<StockDailyClosePrice>> => {
// 	const config: AxiosRequestConfig = {
// 		url: `${apiServerUrl}/api/marketData/${ticker}/today`,
// 		method: "GET",
// 		headers: {
// 			"content-type": "application/json",
// 			Authorization: `Bearer ${accessToken}`,
// 		},
// 	};

// 	const { data, error } = (await fetchAiAnalysis({
// 		config,
// 	})) as StockDailyCloseApiResponse;

// 	return {
// 		data,
// 		error,
// 	};
// };
