import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { Auth0ProviderWithHistory } from './auth0-provider-with-history';
import { createRoot } from 'react-dom/client';
import './styles/styles.css';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!);

root.render(
	<BrowserRouter>
		<Auth0ProviderWithHistory>
			<App />
		</Auth0ProviderWithHistory>
	</BrowserRouter>
);
