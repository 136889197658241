import React from 'react';
import ProductDisplay from './ProductDisplay';

interface SubscriptionPlanProps {
  planName: string;
  price: string;
  productId: string;
  description: string;
  onSelect: (productId: string) => void;
  isSelected: boolean;
}

export const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
  planName,
  price,
  productId,
  description,
  onSelect,
  isSelected,
}) => {
  return (
    <div className="subscription-plan">
      <ProductDisplay
        planName={planName}
        description={description}
        price={price}
        productId={productId}
        onSelect={onSelect}
        isSelected={isSelected}
      />
    </div>
  );
};

export default SubscriptionPlan;