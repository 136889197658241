// src/pages/subscription-page.tsx
import { useEffect, useState } from 'react';
import { SuccessDisplay } from 'src/components/Payment/SuccessDisplay';
import { Message } from 'src/components/Payment/Message';
import { SubscriptionPlan } from 'src/components/Payment/SubscriptionPlan';
import { useAuth0 } from '@auth0/auth0-react';
import { createCheckoutSessions } from 'src/services/stripe.service';
import { PageLayout } from 'src/components/PageLayout';
import LoadingSpinner from 'src/components/Loading/LoadingSpinner';

export const SubscriptionPage: React.FC = () => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');
  let [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>('');
	const { getAccessTokenSilently } = useAuth0();

  const plans = [
    {
      planName: "Starter Plan",
      price: "$5.00",
      productId: "prod_Rbstf1kS0ygy5m",
      description: "Small-scale usage. Basic access to core services. Usage limits of 1,000 API calls/month. Basic email support."
    },
    {
      planName: "Pro Plan",
      price: "$10.00",
      productId: "prod_RbsuLG9aaoI5K8",
      description: "Medium-scale usage. Access to core and advanced services. Usage limits of 5,000 API calls/month. Priority email support. Access to analytics tools for monitoring API usage."
    },
    {
      planName: "Premium Plan",
      price: "$25.00",
      productId: "prod_RbsvfaI7FAIJDN",
      description: "High-scale usage. Access to all services. Unlimited API calls. Priority premium support. Custom integration capabilities through our API."
    }
  ];

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id') ?? '');
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  const handleSelect = (productId: string) => {
    setSelectedProductId(productId);
  };

  const handleCheckout = async () => {
    if (selectedProductId) {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      setError('');

      const sessionData = { productId: selectedProductId };
      const { data, error } = await createCheckoutSessions(
        accessToken,
        sessionData
      );

      if (data.url) {
        window.open(data.url, '_blank');
        setIsLoading(false);
      }

      if (error) {
        setError(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <PageLayout>
      <div className="subscription-page">
        <h1>Choose Your Plan</h1>
        <div className="subscription-plans">
          {plans.map((plan) => (
            <SubscriptionPlan
              key={plan.productId}
              planName={plan.planName}
              price={plan.price}
              productId={plan.productId}
              description={plan.description}
              onSelect={handleSelect}
              isSelected={selectedProductId === plan.productId}
            />
          ))}
        </div>
        {selectedProductId && isLoading == false && (
          <button className="checkout-button" onClick={handleCheckout}>
            Checkout
          </button>
        )}
        {isLoading && <LoadingSpinner/>}
      </div>
    </PageLayout>
  );
};

export default SubscriptionPage;