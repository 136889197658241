import React from 'react';
import { NavLink } from 'react-router-dom';

export const NavBarBrand: React.FC = () => {
	var headerTitle = 'Stock Tracker';
	var subHeaderTitle = 'JDKonsult';
	return (
		<div className="nav-bar__brand">
			<div>
				<NavLink to="/" aria-label={headerTitle}>
					<div className="nav-bar__brand">
						<div className="logo-container">{/* <Logo /> */}</div>
						{typeof headerTitle === 'string' ? (
							<div className="header-title">
								{headerTitle} <br/><span>by {subHeaderTitle}</span>
							</div>
						) : (
							headerTitle
						)}
					</div>
				</NavLink>
			</div>
			{/* <NavLink to="/stockFilings">
                <img
                    className="nav-bar__logo"
                    src={require('../../../images/logo.png')}
                    alt="Auth0 shield logo"
                    width="36"
                    height="15"
                />
            </NavLink> */}
		</div>
	);
};
