import React from 'react';
import { FinancialSummary } from 'src/models/stock-types';

interface StockFinancialSummaryProps {
    financialSummary: FinancialSummary;
}

export const getClassForValue = (value: number, max: number = 100) => {
    const percentage = (value / max) * 100;
    if (percentage >= 0 && percentage < 30 || percentage < 0) {
        return 'red-metric spacing';
    } else if (percentage >= 30 && percentage < 60) {
        return 'orange-metric spacing';
    } else if (percentage >= 60 && percentage <= 100) {
        return 'green-metric spacing';
    }
    return '';
};

export const StockFinancialSummary: React.FC<StockFinancialSummaryProps> = ({
    financialSummary,
}) => {
    return (
        <div>
            <label className="select-label" htmlFor="yearRange">
                Stock Analysis
            </label>
            <div className="financial-summary-container">
                
                <ul>
                    {/* <li className="financial-summary__description">
                        <span className="financial-summary__label">Rating:</span>
                        <span className={getClassForValue(financialSummary.ratingScore, 5)}>{financialSummary.rating}</span>
                    </li> */}
                    <li className="financial-summary__description">
                        <span className="financial-summary__label">JDK Score:</span>
                        <span className={getClassForValue(financialSummary.trendAdjustedScore)}>
                            {Math.round(financialSummary.trendAdjustedScore)} / 100
                        </span>
                    </li>
                    <li className="financial-summary__description">
                        <span className="financial-summary__label">FMP Score:</span>
                        <span className={getClassForValue(financialSummary.ratingScore, 5)}>
                            {financialSummary.ratingScore} / 5
                        </span>
                    </li>
                    <li className="financial-summary__description">
                        <span className="financial-summary__label">Financial Strength:</span>
                        <span className={getClassForValue(financialSummary.financialStrengthPercentage)}>
                            {financialSummary.financialStrengthPercentage}%
                        </span>
                    </li>
                    <li className="financial-summary__description">
                        <span className="financial-summary__label">Purchase Score:</span>
                        <span className={getClassForValue(financialSummary.purchaseAnalysis.scoreScaled)}>
                            {financialSummary.purchaseAnalysis.scoreScaled}%
                        </span>
                    </li>
                    <li className="financial-summary__description">
                        <span className="financial-summary__label">Recommendation:</span>
                        <span className={getClassForValue(financialSummary.ratingScore, 5)}>
                            {financialSummary.ratingRecommendation}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};