import '../../styles/components/loading/loading-spinner.css';
import LoadingDotText from './LoadingDotsText';

interface LoadingSpinnerProps {
	message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message }) => {
	return (
		<div>
			<p className="stockFilingCard-header"></p>
			<div className="spinner-container">
				<div className="lds-ellipsis">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default LoadingSpinner;
