import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { YearlyScore } from 'src/models/stock-types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface StockDetailChartProps {
  yearlyScores: YearlyScore[];
}

const StockDetailChart: React.FC<StockDetailChartProps> = ({ yearlyScores }) => {
  const labels = yearlyScores.map(score => `${score.quarter}-${score.year}`);

  const createDataset = (label: string, data: number[], color: string) => ({
    label,
    data,
    borderColor: color,
    fill: false,
  });

  const data: ChartData<'line'> = {
    labels,
    datasets: [
      createDataset('Revenue Per Share', yearlyScores.map(score => score.scores.revenuePerShare), 'rgba(75,192,192,1)'),
      createDataset('Net Income Per Share', yearlyScores.map(score => score.scores.netIncomePerShare), 'rgba(153,102,255,1)'),
      createDataset('Free Cash Flow Per Share', yearlyScores.map(score => score.scores.freeCashFlowPerShare), 'rgba(255,159,64,1)'),
      createDataset('Gross Profit Margin', yearlyScores.map(score => score.scores.grossProfitMargin), 'rgba(255,99,132,1)'),
      createDataset('Net Profit Margin', yearlyScores.map(score => score.scores.netProfitMargin), 'rgba(54,162,235,1)'),
      createDataset('ROIC', yearlyScores.map(score => score.scores.roic), 'rgba(255,206,86,1)'),
      createDataset('Debt to Equity', yearlyScores.map(score => score.scores.debtToEquity), 'rgba(75,192,192,1)'),
      createDataset('Current Ratio', yearlyScores.map(score => score.scores.currentRatio), 'rgba(153,102,255,1)'),
      createDataset('PE Ratio', yearlyScores.map(score => score.scores.peRatio), 'rgba(255,159,64,1)'),
      createDataset('Dividend Yield', yearlyScores.map(score => score.scores.dividendYield), 'rgba(255,99,132,1)'),
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 14,
            family: 'Space Grotesk',
            weight: 'normal',
          },
          color: '#FFFFFF',
          boxWidth: 15,
          padding: 15,
        },
      },
      title: {
        display: true,
        text: 'Stock Metrics Over Time',
        font: {
          size: 18,
          family: 'Arial',
          weight: 'bold',
        },
        color: '#FFFFFF',
      },
      tooltip: {
        titleFont: {
          size: 14,
          family: 'Arial',
          weight: 'bold',
        },
        bodyFont: {
          size: 12,
          family: 'Arial',
        },
        footerFont: {
          size: 10,
          family: 'Arial',
        },
        backgroundColor: 'rgba(0,0,0,0.7)',
        titleColor: '#fff',
        bodyColor: '#fff',
        footerColor: '#fff',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Metric',
          color: '#FFFFFF',
          font: {
            size: 14,
            family: 'Arial',
            weight: 'bold',
          },
          padding: { top: 10 },
        },
        ticks: {
          color: '#FFFFFF',
          font: {
            size: 16,
            family: 'Arial',
          },

          padding: 10,
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      y: {
        type: 'logarithmic',
        title: {
          display: true,
          text: 'Values',
          font: {
            size: 14,
            family: 'Arial',
            weight: 'bold',
          },
          color: '#FFFFFF',
        },
        ticks: {
          display: true,
          font: {
            size: 12,
            family: 'Arial',
          },
          color: '#FFFFFF',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
  };

  return (
      <Line data={data} options={options} />
  );
};

export default StockDetailChart;