import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../Loading/LoadingSpinner';

interface ProductDisplayProps {
    planName: string;
    price: string;
    productId: string;
    description: string;
    onSelect: (productId: string) => void;
    isSelected: boolean;
}

const ProductDisplay: React.FC<ProductDisplayProps> = ({ planName, price, productId, description, onSelect, isSelected }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>('');

    return (
        <section
            className={`product-display ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(productId)}
        >
            <div className="product">
                <div className="description">
                    <h3>{planName}</h3>
                    <h5>{price} / month</h5>
                </div>
                <div className="plan-details">
                    <ul>
                        {description.split('.').filter(sentence => sentence.trim() !== '').map((sentence, index) => (
                            <li key={index}>{sentence.trim()}.</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div>
                {isLoading ? (
                    <LoadingSpinner />
                ) : error ? (
                    <p className="error-message">Error: {error}</p>
                ) : null}
            </div>
        </section>
    );
};

export default ProductDisplay;