import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PageLoader } from './components/Loading/PageLoader';
import { StockDetailsPage } from './pages/stock-details-page';
import { CallbackPage } from './pages/callback-page';
import { HomePage } from './pages/home-page';
import { NotFoundPage } from './pages/not-found-page';
import { ProfilePage } from './pages/profile-page';
import { StockFilingsPage } from './pages/stock-filings-page';
import { sendAPIWakeupRequest } from './services/stock.service';
import { StockPage } from './pages/stock-page';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import { StockSelectPage } from './pages/stock-select-page';
import { ThemeProvider } from 'next-themes';
import { StockProvider } from './context/StockContext/StockContext';
import Head from './components/Head';
import SubscriptionPage from './pages/subscription-page';
import { SuccessDisplay } from './components/Payment/SuccessDisplay';
import ProductDisplay from './components/Payment/ProductDisplay';
import { StockTopRankedPage } from './pages/stock-top-ranked-page';
import NewsletterSignupPage from './pages/newsletter-signup-page';

export const App: React.FC = () => {
	const { isLoading } = useAuth0();

	useEffect(() => {
		const performApiWakeupRequest = async () => {
			const { error } = await sendAPIWakeupRequest();

			if (error) {
				console.error('Failed to perform API wakeup request: ', error);
			}
		};

		performApiWakeupRequest();
	}, []);

	if (isLoading) {
		return (
			<div className="page-layout">
				<PageLoader />
			</div>
		);
	}

	return (
		<div>
			<StockProvider>
				<Head>
					{/* <ThemeProvider> */}
					<Routes>
						<Route
							path="/"
							element={<AuthenticationGuard component={HomePage} />}
						/>
						<Route
							path="/profile"
							element={<AuthenticationGuard component={ProfilePage} />}
						/>
						<Route
							path="/stockFilings"
							element={<AuthenticationGuard component={StockFilingsPage} />}
						/>
						<Route
							path="/stockDetails"
							element={<AuthenticationGuard component={StockSelectPage} />}
						/>
						<Route
							path="/stockDetails/:stockTicker/*"
							element={<AuthenticationGuard component={StockPage} />}
						/>
						<Route
							path="/stockDetails/:stockTicker/details"
							element={<AuthenticationGuard component={StockDetailsPage} />}
						/>
						<Route
							path="/stockDetails/topRankedStocks"
							element={<AuthenticationGuard component={StockTopRankedPage} />}
						/>
						<Route
							path="/subscription"
							element={<AuthenticationGuard component={SubscriptionPage} />}
						/>
						<Route
							path="/paymentCallback/success"
							element={<AuthenticationGuard component={SubscriptionPage} />}
						/>
						<Route
							path="/newsletter-signup"
							element={<AuthenticationGuard component={NewsletterSignupPage} />}
						/>
						<Route path="/callback" element={<CallbackPage />} />
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
					{/* </ThemeProvider> */}
				</Head>
			</StockProvider>
		</div>
	);
};
